import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Progress,
} from "reactstrap";
import { globalStyles } from "../..";

class Header extends React.Component {
  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-md-2"
          style={{
            backgroundImage: `linear-gradient(to right, ${globalStyles.colors.primary}, ${globalStyles.colors.secondary})`,
          }}
        >
          <Container fluid>
            <div className="header-body">
              <Row>
                {this.props.data.map((d) => {
                  return (
                    <Col
                      xs="12"
                      lg="4"
                      key={d.title}
                      className="mb-4"
                      // onClick={() => this.props.history.push(d.route)}
                      style={{
                        height: "160px",
                      }}
                    >
                      <Card
                        className="card-stats mb-0 mb-xl-0"
                        style={{ height: "150px" }}
                      >
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                // className="text-uppercase text-muted mb-0"
                                className="text-uppercase text-muted mb-2"
                              >
                                {d.title}
                              </CardTitle>
                              {/* {d.description && (
                                <p className="h6 font-weight-normal mb-0">
                                  {d.description}
                                </p>
                              )} */}
                              <span className="p mb-0">{d.count}</span> <br />
                              <span className="h2 font-weight-bold mb-0">
                                {d.value}
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className={d.iconStyle}>
                                <i className={d.icon} />
                              </div>
                            </Col>
                          </Row>
                          <p className="mt-3 mb-0 text-muted text-sm">
                            <Progress
                              max="100"
                              value="100"
                              barClassName={d.barStyle}
                            />
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
