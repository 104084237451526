import React, { useState } from "react";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";

// reactstrap components
import {
  Card,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Form,
  Input,
  Col,
  Spinner,
  Container,
} from "reactstrap";
import { Button } from "@material-ui/core";

const baseUrl = "https://ibedc.irechargetech.com";
class ChangePassword extends React.Component {
  state = {
    current_password: "",
    new_password: "",
    confirm_passowrd: "",
    isLoading: false,
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleChangePassword = async (e) => {
    e.preventDefault();
    if (this.state.confirm_password === "" || this.state.new_password === "") {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields",
      });
      return;
    }
    if (this.state.new_password !== this.state.confirm_password) {
      Swal.fire({
        icon: "error",
        text: "Passwords does not match",
      });
      this.setState({
        isLoading: false,
      });
    }
    //   else if(this.state.new_password !== this.state.confirm_passowrd){
    //     Swal.fire({
    //         icon: 'error',
    //         text: 'passwords does not match',
    //       })
    //       this.setState({
    //         isLoading: false
    //       });
    //   }
    else {
      this.setState({
        isLoading: true,
      });

      try {
        const token = sessionStorage["token"];
        const { new_password, current_password } = this.state;
        const key = CryptoJS.HmacSHA512(token, "12Ekedp34Sx").toString();

        sessionStorage.setItem("passcode", key);

        const data = {
          password: new_password,
          // new_password: new_password,
          // current_password: current_password,
          // token: token,
          // passcode: key,
        };

        const response = await fetch(`${baseUrl}/Update-password`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        });
        const jsonResponse = await response.json();

        if (jsonResponse.success == true) {
          // sessionStorage.setItem("login", JSON.stringify(jsonResponse));
          //   sessionStorage.setItem("token", jsonResponse.token);
          //   sessionStorage.setItem("name", jsonResponse.company_name);
          //   sessionStorage.setItem("isLoggedIn", true);
          Swal.fire({
            icon: "success",
            text: "Password Changed successfully, please Login",
            position: "top-end",
            showClass: {
              popup: "animated fadeInDown faster",
            },
            hideClass: {
              popup: "animated fadeOutUp faster",
            },
          });
          this.setState({
            isLoading: false,
          });
          this.props.history.push("/auth/login");
        } else {
          Swal.fire({
            icon: "error",
            text: jsonResponse.message,
            position: "top-end",
          });
          this.setState({
            isLoading: false,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Unable to connect",
          text: "Please check your internet connection and try again",
        });
      }
      this.setState({
        isLoading: false,
      });
    }
  };

  // handlePassword = (e) => {
  //   e.preventDefault();
  //   this.props.history.push('/auth/reset_password');

  // }

  render() {
    const { isLoading } = this.state;
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>

        <Container className=" mt--8 pb-5" fluid="xl">
          <div className="justify-content-center col">
            <Col className="col-sm-12  col-md-7 offset-md-2" fluid>
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <h2>Change Password</h2>
                  </div>
                  <Form role="form">
                    {/* <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={this.handleChange}
                          name="current_password"
                          placeholder="Current Password"
                          type="password"
                          autocomplete="off"
                        />
                      </InputGroup>
                    </FormGroup> */}
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={this.handleChange}
                          name="new_password"
                          placeholder="New Password"
                          type="password"
                          autocomplete="off"
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={this.handleChange}
                          name="confirm_password"
                          placeholder="Confirm Password"
                          type="password"
                          autocomplete="off"
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                      />
                    </div>
                    <div>
                      <Spinner color="primary" />
                    </div>
                    <div className="text-center">
                      <Button
                        style={{ color: "white", margin: "16px 0" }}
                        color="secondary"
                        variant="contained"
                        type="button"
                        onClick={this.handleChangePassword}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <span>
                            <i className="fas fa-spinner fa-spin"></i>Please
                            wait...
                          </span>
                        ) : (
                          "Change Password"
                        )}
                      </Button>
                      <a
                        className="text-right"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        {/* <small onClick={this.handlePassword}>Forgot password?</small> */}
                      </a>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </div>
        </Container>
      </>
    );
  }
}
export default ChangePassword;
