import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Form,
  Input,
  Col,
  Spinner,
  Container,
  Label
} from 'reactstrap';


class CreateUser extends React.Component {
  state = {
    phone: '',
    username: '',
    role: 'admin',
    isLoading: false
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleChangePassword = async (e) => {
    e.preventDefault();
    if (this.state.phone == '' || this.state.username == '') {
      Swal.fire({
        icon: 'error',
        text: 'Please fill all fields',
      })
      this.setState({
        isLoading: false
      });
    }
    //   else if(this.state.new_password !== this.state.confirm_passowrd){
    //     Swal.fire({
    //         icon: 'error',
    //         text: 'passwords does not match',
    //       }) 
    //       this.setState({
    //         isLoading: false
    //       });
    //   }
    else if (!this.validateEmail(this.state.username)) {
      Swal.fire({
        icon: 'error',
        text: 'Username must be a valid email',
      })
    } else if (this.state.phone.length !== 11) {
      Swal.fire({
        icon: 'error',
        text: 'Phone number must have 11 digits',
      })
    }
    else {
      this.setState({
        isLoading: true
      });



      try {
        const token = sessionStorage['token'];
        const { phone, username, role } = this.state;
        const key = CryptoJS.HmacSHA512(token, "12Ekedp34Sx").toString();

        sessionStorage.setItem("passcode", key);

        const data = {
          phone,
          username,
          role,
          token,
          passcode: key
        }

        const response = await fetch('https://irecharge.com.ng/api/ekedp/create_user.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        });
        const jsonResponse = await response.json();

        if (jsonResponse.status === '00') {
          // sessionStorage.setItem("login", JSON.stringify(jsonResponse));
          //   sessionStorage.setItem("token", jsonResponse.token);
          //   sessionStorage.setItem("name", jsonResponse.company_name);
          //   sessionStorage.setItem("isLoggedIn", true);
          Swal.fire({
            icon: 'success',
            text: 'User created successfully',
            position: 'top-end',
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
          this.setState({
            isLoading: false
          })
          this.props.history.push('/admin/users');
        } else {
          Swal.fire({
            icon: 'error',
            text: jsonResponse.message,
            position: 'top-end'
          })
          this.setState({
            isLoading: false
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Unable to connect',
          text: 'Please check your internet connection and try again'
        })
      }
      this.setState({
        isLoading: false
      });
    }
  }

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  // handlePassword = (e) => {
  //   e.preventDefault();
  //   this.props.history.push('/auth/reset_password');

  // }


  render() {
    const { isLoading } = this.state;
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>

        <Container className=" mt--8 pb-5" fluid="xl">
          <div className="justify-content-center col">
            {/* <Col className="col-sm-12  col-md-7 offset-md-2" fluid> */}
            <Col className="col-sm-12  col-md-7 offset-md-2">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <h2>Create User</h2>
                  </div>
                  <Form role="form">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input onChange={this.handleChange} name="phone" placeholder=" Phone" type="text" autocomplete="off" />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input onChange={this.handleChange} name="username" placeholder="User Name" type="email" autocomplete="off" />
                      </InputGroup>
                    </FormGroup>
                    <Col className="col-sm-12  col-md-12" fluid>
                      <FormGroup className="mb-3">
                        <Label for="exampleSelect">Select Role</Label>
                        <Input type="select" name="role" id="exampleSelect" onChange={this.handleChange}>
                          <option value={"admin"} defaultValue>Admin</option>
                          <option value={"representative"}>Representative</option>

                        </Input>
                      </FormGroup>
                    </Col>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                      />
                    </div>
                    <div>
                      <Spinner color="primary" />
                    </div>
                    <div className="text-center">
                      <Button className="my-4" color="success" type="button" onClick={this.handleChangePassword} disabled={isLoading}>
                        {isLoading ? <span ><i className="fas fa-spinner fa-spin"></i>Please wait...</span> : 'Submit'}
                      </Button>
                      <a className="text-right"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        {/* <small onClick={this.handlePassword}>Forgot password?</small> */}
                      </a>
                    </div>
                  </Form>

                </CardBody>
              </Card>
            </Col>
          </div>
        </Container>
      </>
    );
  };
};
export default CreateUser;


