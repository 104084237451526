/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components

// **************************************************************************************

import jwt_decode from "jwt-decode";
import React, { useState, useLayoutEffect } from "react";

import FulfilledAdminTransactions from "./FulfilledAdminTransactions";
import FulfilledSuperAdminTransactiions from "./FulfilledSuperAdminTransactions";
// import RedirectToLogin from "./RedirectToLogin";

// const token = sessionStorage["token"];

const Fulfilled = () => {
  const [token, setToken] = useState();

  let decoded;
  let role;

  useLayoutEffect(() => {
    if (sessionStorage["token"]) {
      setToken(sessionStorage["token"]);
    }
  }, [sessionStorage["token"]]);

  if (token) {
    decoded = jwt_decode(token);
  }

  if (decoded) {
    role =
      decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
  }

  const isSuperAdmin = role === "super";

  // const isUser = role === "user";

  return isSuperAdmin ? (
    <FulfilledSuperAdminTransactiions />
  ) : (
    <FulfilledAdminTransactions />
  );
};

export default Fulfilled;
