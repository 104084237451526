/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
import React, { useState } from 'react';
import moment from 'moment';
import ReactExport from "react-export-excel";
import { CSVLink } from 'react-csv';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined';
import PrintIcon from '@material-ui/icons/Print';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {
  Button,
  ButtonGroup,
  colors,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TablePagination,
  Typography,
  Paper,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import CryptoJS from "crypto-js";

// core components
import Header from "components/Headers/Header.jsx";
import Swal from "sweetalert2";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 700
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  norec: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column'
  },
  search: {
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingLeft: 0,
    display: 'flex',
    justifyContent: 'space-between',
    '@media print': {
      display: 'none'
    }
  },
  searchMobile: {
    padding: theme.spacing(3),
    display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '@media print': {
      display: 'none'
    }
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2)
  },
  action: {
    display: 'flex',
    justifyContent: 'center'
  },
  createBtn: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3)
  },
  form: {
    marginTop: theme.spacing(3),
    width: '100%',
    marginRight: theme.spacing(4)
  },
  formMobile: {
    marginTop: theme.spacing(3),
    minWidth: '80%',
    marginRight: theme.spacing(0)
  },
  text: {
    height: '100%'
  },
  createBtnMobile: {
    display: 'block',
    padding: '0 1.5rem',
    marginTop: theme.spacing(6)
  },
  right: {
    display: 'flex',
    width: '40%'
  },
  rightMobile: {
    display: 'flex',
    width: '80%'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    width: '90%',
    margin: '3rem auto 3rem'
  },
  contentMobile: {
    flexGrow: 1,
    padding: theme.spacing(0),
    width: '90%',
    margin: '3rem auto 0'
  },
  dates: {
    padding: 100
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  printButton: {
    color: 'white',
    backgroundColor: colors.cyan[600],
    '&:hover': {
      backgroundColor: colors.cyan[900]
    },
    margin: theme.spacing(3),
    '@media print': {
      display: 'none'
    }
  },
  printIcon: {
    color: colors.white,
    backgroundColor: colors.cyan[600],
    '&:hover': {
      backgroundColor: colors.cyan[900]
    },
    marginRight: theme.spacing(1)
  },
  exportButton: {
    color: colors.white,
    backgroundColor: colors.teal[600],
    '&:hover': {
      backgroundColor: colors.teal[900]
    },
    margin: theme.spacing(3),
    '@media print': {
      display: 'none'
    }
  },
  exportIcon: {
    color: 'white',
    backgroundColor: colors.teal[600],
    '&:hover': {
      backgroundColor: colors.teal[900]
    },
    marginRight: theme.spacing(1)
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    padding: '1rem 1rem 0',
    height: '2rem'
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    // [theme.breakpoints.down('sm')]: {
    //   '&:not(:last-of-type)': {
    //     borderBottom: `1px solid ${theme.palette.divider}`
    //   }
    // }
  },
  printContainer: {
    '@media print': {

      marginLeft: -250,
    },
  },
  getBtn: {
    marginLeft: '25px',
    marginTop: '20px',
  }
}));

const Fulfilled = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(
    moment().subtract(240, 'days').format('Y-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment().format('Y-MM-DD'));
  const [selectEdge, setSelectEdge] = useState(null);
  const [calendarDate, setCalendarDate] = useState(moment());
  const [transactions, setTransactions] = useState([]);
  const [fulfilled, setFulfilled] = useState({})
  const [transaction, setTransaction] = useState([]);
  const [view, setView] = useState(false);
  const [loading, isLoading] = useState(false)
  const open = Boolean(selectEdge);
  const [page, setPage] = React.useState(1);
  const [all, setAll] = React.useState(false);
  // const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dataToExport = [...transactions, {
    customer_meter_number: '',
    customer_account_type: '',
    payment_reference: '',
    requested_at: '',
    amount: '',

  }]
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleCalendarOpen = edge => {
    setSelectEdge(edge);
  };

  const handleCalendarChange = date => {
    setCalendarDate(date);
  };

  const handleCalendarClose = () => {
    setCalendarDate(moment());
    setSelectEdge(null);
  };

  const handleCalendarAccept = date => {
    setCalendarDate(moment());

    if (selectEdge === 'start') {
      setStartDate(moment(date).format('Y-MM-DD'));

      if (moment(date).isAfter(endDate)) {
        setEndDate(moment(date).format('Y-MM-DD'));
      }
    } else {
      setEndDate(moment(date).format('Y-MM-DD'));

      if (moment(date).isBefore(startDate)) {
        setStartDate(moment(date).format('Y-MM-DD'));
      }
    }

    setSelectEdge(null);
  };

  const getFulfilledTransaction = async () => {
    const token = sessionStorage['token'];
    const url = `https://vendcare.com.ng/bedc/api/v2/manager/service/reversal-logs?all=${all}&from=${startDate}&to=${endDate}&page=${page}`;
    isLoading(true);

    try {
      const response = await fetch(url, {
        method: 'GET'
      });
      const result = await response.json();
      console.log(result)
      if (result.status === 'OK') {
        setTransactions(result.data.items);
        setFulfilled(result);
      }
      isLoading(false)
    } catch (error) {
      console.log(error);
      Swal.fire('Error', 'Something went wrong!', 'error');
    }
  }
  const viewReversalDetails = async (logId, powerId) => {
    const url = `https://vendcare.com.ng/bedc/api/v2/manager/service/reversal-logs/${logId}/transactions/${powerId}`;
    // isLoading(true);
    try {
      const response = await fetch(url, {
        method: 'GET'
      });
      const result = await response.json();
      console.log(result)
      if (result.status === 'OK') {
        setTransaction(result.data);
        setView(true);
      }
      isLoading(false)
    } catch (error) {
      console.log(error);
      Swal.fire('Error', 'Something went wrong!', 'error');
    }
  }

  const format_str = (string) => {
    return string.replaceAll(" ", "_").toLowerCase()
  }

  React.useEffect(() => {
    getFulfilledTransaction();
  }, []);

  if (loading) {
    return (
      <h1 className="text-center mt-5">Loading, please wait</h1>
    )
  }

  return (
    <div className={classes.printContainer}>

      <Header />
      {/* Page content */}

      <Container className="mt--7" fluid>
        {/* Table */}
        {/* Dark table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0 pb-0">
                <h3 className="text-white mb-0">Reversal List</h3>
              </CardHeader>
              <div className={classes.search}>

                <div>
                  {/* <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => window.print()}
                    className={classes.printButton}>
                    <PrintIcon className={classes.printIcon} /> Print
                  </Button> */}
                  <ExcelFile filename={`Reversal Log for ${startDate} - ${endDate}`} element={
                    <Button style={{ color: "white" }}
                      variant="outlined"
                      color="primary"
                      className={classes.exportButton}>
                      <SaveAltIcon className={classes.exportIcon} />
                      Export
                    </Button>
                  }>
                    <ExcelSheet data={dataToExport} name="Reversal">
                      <ExcelColumn label={format_str("customer_meter_number")} value="customer_meter_number" />
                      <ExcelColumn label={format_str("customer_account_type")} value="customer_account_type" />
                      <ExcelColumn label={format_str("Payment Reference")} value="payment_reference" />
                      <ExcelColumn label={format_str("requested_at")} value="requested_at" />
                      <ExcelColumn label={format_str("Amount")} value="amount" />    
                    </ExcelSheet>
                  </ExcelFile>

                </div>
                <div>
                  <ButtonGroup variant="contained" className={classes.createBtn}>
                    <Button onClick={() => handleCalendarOpen('start')}>
                      <CalendarTodayIcon className={classes.calendarTodayIcon} />
                      {startDate}
                    </Button>
                    <Button onClick={() => handleCalendarOpen('end')}>
                      <CalendarTodayIcon className={classes.calendarTodayIcon} />
                      {endDate}
                    </Button>
                  </ButtonGroup>
                  <div>
                    <Button style={{ backgroundColor: "#00897B", color: "white" }}
                      variant="outlined"
                      color="primary"
                      className={classes.getBtn}
                      onClick={getFulfilledTransaction}>
                      Get Report
                    </Button>
                  </div>
                </div>
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  maxDate={moment()}
                  onAccept={handleCalendarAccept}
                  onChange={handleCalendarChange}
                  onClose={handleCalendarClose}
                  open={open}
                  style={{ display: 'none' }} // Temporal fix to hide the input element
                  value={calendarDate}
                  variant="dialog"
                />
              </MuiPickersUtilsProvider>
              {!transactions ? <Typography variant="h5" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', color: '#fff', margin: '2rem' }}>No reversal found</Typography> :
                (<>
                  <Table
                    className="align-items-center table-dark table-flush"
                    responsive
                  >
                    <thead className="thead-dark" style={{ marginBottom: 15 }}>
                      <tr>
                        <th scope="col">Customer Meter Number</th>
                        <th scope="col">Customer Account Type</th>
                        <th scope="col">Payment Reference</th>
                        <th scope="col">Amount</th>
                        <th scope="col"> Requested Date</th>
                        <th scope="col">View  </th>
                        <th scope="col" />
                      </tr>
                    </thead>
                   {transactions == "" ? <Typography variant="h5" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', color: '#fff', margin: '2rem' }}>No reversal found</Typography> :
                    <tbody>
                      {
                        transactions.map((transaction, i) => {
                          return (
                            <tr>

                              <td>{transaction.customer_meter_number}</td>
                              <td>{transaction.customer_account_type}</td>
                              <td>{transaction.payment_reference}</td>
                              <td>{transaction.amount}</td>
                              <td>{transaction.requested_at}</td>
                              <td><Button variant="outlined" style={{ color: '#fff', borderColor: '#fff' }} onClick={() => viewReversalDetails(transaction.id, transaction.power_transaction_id)}>View</Button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                      }
                  </Table>
                  <CardFooter className="py-4 bg-default shadow">
                    <nav aria-label="...">
                      <TablePagination
                        component="div"
                        count={transactions.length}
                        page={page - 1}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        style={{ color: '#fff' }}
                      />
                    </nav>
                  </CardFooter>
                  <Dialog onClose={() => setView(false)} aria-labelledby="simple-dialog-title" open={view} fullWidth={true} maxWidth="md">
                    <DialogTitle id="simple-dialog-title">Reversal Detail</DialogTitle>
                    <TableContainer component={Paper}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell align="right">Value</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Name
                            </TableCell>
                            <TableCell align="right">{transaction.customer_name ? transaction.customer_name : "No Record"}</TableCell>
                          </TableRow>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Phone
                            </TableCell>
                            <TableCell align="right">{transaction.phone ? transaction.phone : "No Record"}</TableCell>
                          </TableRow>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Email
                            </TableCell>
                            <TableCell align="right">{transaction.email ? transaction.email : "No Record"}</TableCell>
                          </TableRow>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Transaction ID
                            </TableCell>
                            <TableCell align="right">{transaction.transaction_id ? transaction.transaction_id : "No Record"}</TableCell>
                          </TableRow>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Provider
                            </TableCell>
                            <TableCell align="right">{transaction.provider ? transaction.provider : "No Record"}</TableCell>
                          </TableRow>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Meter Number
                            </TableCell>
                            <TableCell align="right">{transaction.meter_number ? transaction.meter_number : "No Record"}</TableCell>
                          </TableRow>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Account Number
                            </TableCell>
                            <TableCell align="right">{transaction.meter_number ? transaction.meter_number : "No Record"}</TableCell>
                          </TableRow>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Payment Reference
                            </TableCell>
                            <TableCell align="right">{transaction.payment_reference ? transaction.payment_reference : "No Record"}</TableCell>
                          </TableRow>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Customer Address
                            </TableCell>
                            <TableCell align="right">{transaction.customer_address ? transaction.customer_address : "No Record"}</TableCell>
                          </TableRow>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Status
                            </TableCell>
                            <TableCell align="right">{transaction.status ? transaction.status : "No Record"}</TableCell>
                          </TableRow>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Amount
                            </TableCell>
                            <TableCell align="right">{transaction.amount ? transaction.amount : "No Record"}</TableCell>
                          </TableRow>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Token 
                            </TableCell>
                            <TableCell align="right">{transaction.token ? transaction.token: "No Record"}</TableCell>
                          </TableRow>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                     
                            <TableCell component="th" scope="row">
                              Units
                            </TableCell>
                            <TableCell align="right">{transaction.unit ? transaction.unit : "No Record"}</TableCell>
                          </TableRow>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Requested At
                            </TableCell>
                            <TableCell align="right">{transaction.requested_at ? transaction.requested_at : "No Record"}</TableCell>
                          </TableRow>
                          
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Status
                            </TableCell>
                            <TableCell align="right">{transaction.status ? transaction.status : "No Record"}</TableCell>
                          </TableRow>
                          
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Provider Status
                            </TableCell>
                            <TableCell align="right">{transaction.provider_status ? transaction.provider_status : "No Record"}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Dialog>
                </>)}
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}


export default Fulfilled;
