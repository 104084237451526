import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Swal from "sweetalert2";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";

import { formatCurrency } from "../FulfilledAdminTransactions";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  title: {
    fontWeight: 700,
    fontSize: 32,
    paddingRight: 16,
  },
  tableHead: {
    fontWeight: 600,
    marginBottom: 16,
    marginTop: 16,
  },
  columnHead: {
    fontWeight: 600,
  },
});

export default function Top5Modal({ open, setOpen, baseUrl }) {
  const classes = useStyles();
  const [merchantLeaderboard, setMerchantLeaderboard] = React.useState(null);
  const [regionLeaderboard, setRegionLeaderboard] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const getMerchantLeaderboard = async () => {
    const token = sessionStorage["token"];
    const url = `${baseUrl}/leaderboard`;
    setIsLoading(true);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (result && result.success === true) {
        setMerchantLeaderboard(
          result.data.sort((a, b) => b.total - a.total).slice(0, 5)
        );
      }
    } catch (error) {
      console.error(error);
      Swal.fire("Error", "Something went wrong!", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const getRegionLeaderboard = async () => {
    const token = sessionStorage["token"];
    const url = `${baseUrl}/region-table`;
    setIsLoading(true);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (result && result.success === true) {
        // setRegionLeaderboard(result.data.slice(0, 5));
        setRegionLeaderboard(
          result.data.sort((a, b) => b.amount - a.amount).slice(0, 5)
        );
      }
    } catch (error) {
      console.error(error);
      Swal.fire("Error", "Something went wrong!", "error");
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getMerchantLeaderboard();
    getRegionLeaderboard();
  }, []);
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span className={classes.title}>TOP 5</span>{" "}
          {isLoading && <CircularProgress />}
        </DialogTitle>
        <DialogContent dividers>
          <Typography component="h3" variant="h5" className={classes.tableHead}>
            Merchants
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnHead}>Name</TableCell>
                  <TableCell align="right" className={classes.columnHead}>
                    Count
                  </TableCell>
                  <TableCell align="right" className={classes.columnHead}>
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {merchantLeaderboard &&
                  merchantLeaderboard.map((leader, i) => {
                    return (
                      <TableRow key={leader.name}>
                        <TableCell component="th" scope="row">
                          {leader.name}
                        </TableCell>
                        <TableCell align="right">{leader.count}</TableCell>
                        <TableCell align="right">
                          {formatCurrency(leader.total)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography component="h3" variant="h5" className={classes.tableHead}>
            Regions
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnHead}>Name</TableCell>
                  <TableCell align="right" className={classes.columnHead}>
                    Count
                  </TableCell>
                  <TableCell align="right" className={classes.columnHead}>
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {regionLeaderboard &&
                  regionLeaderboard.map((leader, i) => {
                    return (
                      <TableRow key={leader.name}>
                        <TableCell component="th" scope="row">
                          {leader.sp}
                        </TableCell>
                        <TableCell align="right">{leader.count}</TableCell>
                        <TableCell align="right">
                          {formatCurrency(leader.amount)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}
