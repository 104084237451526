/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactExport from "react-export-excel";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import CalendarTodayIcon from "@material-ui/icons/CalendarTodayOutlined";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import {
  Button,
  ButtonGroup,
  colors,
  Dialog,
  DialogTitle,
  Typography,
  Paper,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  CircularProgress,
  // Label,
  // Col,
} from "@material-ui/core";
import { FormGroup, Input, Col, Label } from "reactstrap";

import DateFnsUtils from "@date-io/date-fns";
import CryptoJS from "crypto-js";

// core components
import Header from "components/Headers/Header.jsx";
import Swal from "sweetalert2";
import { globalStyles } from "../..";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  norec: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flexDirection: "column",
  },
  search: {
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingLeft: 0,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "@media print": {
      display: "none",
    },
  },
  searchMobile: {
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "@media print": {
      display: "none",
    },
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  action: {
    display: "flex",
    justifyContent: "center",
  },
  createBtn: {},
  form: {
    marginTop: theme.spacing(3),
    width: "100%",
    marginRight: theme.spacing(4),
  },
  formMobile: {
    marginTop: theme.spacing(3),
    minWidth: "80%",
    marginRight: theme.spacing(0),
  },
  text: {
    height: "100%",
  },
  createBtnMobile: {
    display: "block",
    padding: "0 1.5rem",
    marginTop: theme.spacing(6),
  },
  right: {
    display: "flex",
    width: "40%",
  },
  rightMobile: {
    display: "flex",
    width: "80%",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    width: "90%",
    margin: "3rem auto 3rem",
  },
  contentMobile: {
    flexGrow: 1,
    padding: theme.spacing(0),
    width: "90%",
    margin: "3rem auto 0",
  },
  dates: {
    padding: 100,
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1),
  },
  printButton: {
    color: "white",
    backgroundColor: colors.cyan[600],
    "&:hover": {
      backgroundColor: colors.cyan[900],
    },
    margin: theme.spacing(3),
    "@media print": {
      display: "none",
    },
  },
  printIcon: {
    color: colors.white,
    backgroundColor: colors.cyan[600],
    "&:hover": {
      backgroundColor: colors.cyan[900],
    },
    marginRight: theme.spacing(1),
  },
  exportButton: {
    color: colors.white,
    margin: theme.spacing(3),
    "@media print": {
      display: "none",
    },
  },
  exportIcon: {
    color: "white",
    backgroundColor: "transaparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    marginRight: theme.spacing(1),
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    padding: "1rem 1rem 0",
    height: "2rem",
  },
  item: {
    padding: theme.spacing(3),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      "&:not(:last-of-type)": {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  printContainer: {
    "@media print": {
      marginLeft: -250,
    },
  },
  getBtn: {
    // marginLeft: "25px",
    margin: "16px 0",
    height: "2.75rem",
  },
  label: {
    color: "white",
  },
  paper2: {
    padding: "1rem",
  },
}));

const GetAccount = () => {
  const classes = useStyles();
  const [billerCode, setBillerCode] = useState("ibedc");
  const [meterNumber, setMeterNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountDetails, setAccountDetails] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  //   const [isRetrieved, setIsRetrieved] = useState(false);

  //   data.passcode = CryptoJS.HmacSHA512(
  //     meterNumber,
  //     `${process.env.NEXT_PUBLIC_PASSCODE_HASHER}`
  //   ).toString();

  const canRetrieve =
    isVerified && accountDetails && accountDetails.account_number === "";

  const validateMeter = (number) => {
    const re = /^[\d\/\-]+$/; // match digits, hyphen and forward-slash
    return re.test(String(number).toLowerCase());
  };

  const verifyAccount = async () => {
    const passcode = CryptoJS.HmacSHA512(
      meterNumber,
      "5OoQRA8CTXw3"
    ).toString();

    if (!validateMeter(meterNumber)) {
      Swal.fire("Error", "Invalid Meter Number", "error");
      return;
    }

    const body = { biller_code: billerCode, passcode, reference: meterNumber };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    let url = "https://irecharge.com.ng/api/v2/live/adept/verify.php";
    setLoading(true);

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      if (result.status === "00") {
        setAccountDetails(result);
        setIsVerified(true);
        setName("");
        setEmail("");
        setPhone("");
        // if (isExport) {
        //   Swal.fire("Success", result.message, "success");
        //   return;
        // }
      } else {
        setAccountDetails(null);
        Swal.fire("Error", result.message, "error");
      }
    } catch (error) {
      console.log(error);
      setAccountDetails(null);
      Swal.fire("Error", "Something went wrong!", "error");
    } finally {
      setLoading(false);
    }
  };

  const retrieveAccount = async () => {
    const passcode = CryptoJS.HmacSHA512(
      meterNumber,
      "5OoQRA8CTXw3"
    ).toString();

    if (!phone) {
      Swal.fire("Error", "Phone No. is required", "error");
      return;
    }

    const body = {
      biller_code: billerCode,
      passcode,
      reference: meterNumber,
      full_name: name,
      email,
      phone,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    let url = "https://irecharge.com.ng/api/v2/live/adept/retrieve.php";
    setIsLoading(true);

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      if (result.status === "00") {
        setAccountDetails(result);
        // if (isExport) {
        //   Swal.fire("Success", result.message, "success");
        //   return;
        // }
      } else {
        // setAccountDetails(null);
        Swal.fire("Error", result.message, "error");
      }
    } catch (error) {
      console.log(error);
      //   setAccountDetails(null);
      Swal.fire("Error", "Something went wrong!", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.printContainer}>
      <Header />
      {/* Page content */}

      <Container className="mt--7" fluid>
        {/* {filterLoading && (
          <div className="page-loader" style={{ textAlign: "center" }}>
            <i className="fas fa-spinner fa-spin" style={{ color: "blue" }}></i>
          </div>
        )} */}
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0 pb-0"></CardHeader>
              <div className={classes.search}>
                <div>
                  <Container fluid>
                    <Row className="">
                      {/* <Col className="col-sm-12  col-md-4" fluid> */}
                      <Col className="col-12 col-lg-4">
                        <FormGroup>
                          <Label for="biller_code" className={classes.label}>
                            Select Service
                          </Label>
                          <Input
                            type="select"
                            name="biller_code"
                            id="biller_code"
                            onChange={(e) => setBillerCode(e.target.value)}
                            value={billerCode}
                          >
                            <option value="ibedc">
                              IBEDC (Ibadan) Prepaid
                            </option>
                            <option value="ibedc_postpaid">
                              IBEDC (Ibadan) Postpaid
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      {/* <Col className="col-12 col-md-4" fluid> */}
                      <Col className="col-12 col-lg-4" fluid>
                        <FormGroup>
                          <Label for="meter_number" className={classes.label}>
                            Meter Number
                          </Label>
                          <Input
                            type="text"
                            name="meter_number"
                            id="meter_number"
                            value={meterNumber}
                            onChange={(e) => setMeterNumber(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-12  col-lg-4 mt-lg-3" fluid>
                        <Button
                          fullWidth
                          style={{
                            color: "white",
                          }}
                          variant="contained"
                          color="secondary"
                          className={classes.getBtn}
                          onClick={verifyAccount}
                          disabled={loading || isLoading}
                        >
                          {loading ? (
                            // <i className="fas fa-spinner fa-spin"></i>
                            <CircularProgress color="secondary" size={24} />
                          ) : (
                            "Verify Account"
                          )}
                        </Button>
                      </Col>
                    </Row>
                    {/* ROW 2 */}
                    {canRetrieve && (
                      <Row className="">
                        <Col className="col-12  col-lg-3" fluid>
                          <FormGroup>
                            <Label for="phone" className={classes.label}>
                              Phone Number
                              <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              name="phone"
                              id="phone"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-12  col-lg-3" fluid>
                          <FormGroup>
                            <Label for="name" className={classes.label}>
                              Your Name
                            </Label>
                            <Input
                              type="text"
                              name="name"
                              id="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </FormGroup>
                        </Col>

                        <Col className="col-12  col-lg-3" fluid>
                          <FormGroup>
                            <Label for="email" className={classes.label}>
                              Email
                            </Label>
                            <Input
                              type="text"
                              name="email"
                              id="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-12  col-lg-3 mt-3" fluid>
                          <Button
                            fullWidth
                            style={{
                              color: "white",
                            }}
                            variant="contained"
                            color="secondary"
                            className={classes.getBtn}
                            onClick={retrieveAccount}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              // <i className="fas fa-spinner fa-spin"></i>
                              <CircularProgress color="secondary" size={24} />
                            ) : (
                              "Get Account"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    )}
                    {/* ROW 3 */}
                    {accountDetails && accountDetails.account_number && (
                      <Row>
                        <Col>
                          <Paper className={classes.paper2}>
                            <p>
                              <b>Account Name:</b> {accountDetails.account_name}
                            </p>
                            <p>
                              <b>Account Number:</b>{" "}
                              {accountDetails.account_number}
                            </p>
                            <p>
                              <b>Bank:</b> {accountDetails.bank}
                            </p>

                            <p>
                              <b>Customer Name:</b>{" "}
                              {accountDetails.customer_name}
                            </p>
                            <p>
                              <b>Customer Phone:</b>{" "}
                              {accountDetails.customer_phone}
                            </p>

                            <p>
                              <b>Meter Number:</b> {accountDetails.reference}
                            </p>
                            <p>
                              <b>Customer Email:</b>{" "}
                              {accountDetails.customer_email}
                            </p>
                            <p>
                              <b>Customer Address:</b>{" "}
                              {accountDetails.customer_address}
                            </p>
                          </Paper>
                        </Col>
                      </Row>
                    )}
                  </Container>
                  {/* {!!loading && (
                    <div style={{ textAlign: "center", padding: "1rem" }}>
                      <CircularProgress color="secondary" />
                    </div>
                  )} */}

                  {/* <div>
                    <Row>
                      <Col className="col-sm-12  col-md-6">
                        <Button
                          style={{
                            color: "white",
                          }}
                          variant="contained"
                          color="secondary"
                          className={classes.getBtn}
                          onClick={() => () => null}
                          disabled={loading}
                        >
                          {filterLoading ? (
                            <i className="fas fa-spinner fa-spin"></i>
                          ) : (
                            "Get Report"
                          )}
                        </Button>
                      </Col>
                      <Col
                        className="col-sm-12  col-md-6"
                        style={{ textAlign: "right" }}
                      >
                        <Button
                          style={{
                            color: "white",
                          }}
                          variant="contained"
                          color="secondary"
                          className={classes.exportButton}
                          onClick={() => null}
                          disabled={loading}
                        >
                          <SaveAltIcon className={classes.exportIcon} />
                          Export
                        </Button>
                      </Col>
                    </Row>
                  </div> */}
                </div>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default GetAccount;
