/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactExport from "react-export-excel";
import { CSVLink } from 'react-csv';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined';
import PrintIcon from '@material-ui/icons/Print';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {
  Button,
  ButtonGroup,
  colors,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TablePagination,
  Typography,
  Paper,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
} from '@material-ui/core';
import {

  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Col,
  Spinner,
  Label
} from "reactstrap";

import DateFnsUtils from '@date-io/date-fns';
import CryptoJS from "crypto-js";

// core components
import Header from "components/Headers/Header.jsx";
import Swal from "sweetalert2";
import Loader from "../Loader";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 700
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  norec: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column'
  },
  search: {
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingLeft: 0,
    display: 'flex',
    justifyContent: 'space-between',
    '@media print': {
      display: 'none'
    }
  },
  searchMobile: {
    padding: theme.spacing(3),
    display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '@media print': {
      display: 'none'
    }
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2)
  },
  action: {
    display: 'flex',
    justifyContent: 'center'
  },
  createBtn: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3)
  },
  form: {
    marginTop: theme.spacing(3),
    width: '100%',
    marginRight: theme.spacing(4)
  },
  formMobile: {
    marginTop: theme.spacing(3),
    minWidth: '80%',
    marginRight: theme.spacing(0)
  },
  text: {
    height: '100%'
  },
  createBtnMobile: {
    display: 'block',
    padding: '0 1.5rem',
    marginTop: theme.spacing(6)
  },
  right: {
    display: 'flex',
    width: '40%'
  },
  rightMobile: {
    display: 'flex',
    width: '80%'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    width: '90%',
    margin: '3rem auto 3rem'
  },
  contentMobile: {
    flexGrow: 1,
    padding: theme.spacing(0),
    width: '90%',
    margin: '3rem auto 0'
  },
  dates: {
    padding: 100
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  printButton: {
    color: 'white',
    backgroundColor: colors.cyan[600],
    '&:hover': {
      backgroundColor: colors.cyan[900]
    },
    margin: theme.spacing(3),
    '@media print': {
      display: 'none'
    }
  },
  printIcon: {
    color: colors.white,
    backgroundColor: colors.cyan[600],
    '&:hover': {
      backgroundColor: colors.cyan[900]
    },
    marginRight: theme.spacing(1)
  },
  exportButton: {
    color: colors.white,
    backgroundColor: colors.teal[600],
    '&:hover': {
      backgroundColor: colors.teal[900]
    },
    margin: theme.spacing(3),
    '@media print': {
      display: 'none'
    }
  },
  exportIcon: {
    color: 'white',
    backgroundColor: colors.teal[600],
    '&:hover': {
      backgroundColor: colors.teal[900]
    },
    marginRight: theme.spacing(1)
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    padding: '1rem 1rem 0',
    height: '2rem'
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    // [theme.breakpoints.down('sm')]: {
    //   '&:not(:last-of-type)': {
    //     borderBottom: `1px solid ${theme.palette.divider}`
    //   }
    // }
  },
  printContainer: {
    '@media print': {

      marginLeft: -250,
    },
  },
  getBtn: {
    marginLeft: '25px',
    marginTop: '20px',
  }
}));

const Fulfilled = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(
    moment().subtract(240, 'days').format('Y-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment().format('Y-MM-DD'));
  const [selectEdge, setSelectEdge] = useState(null);
  const [calendarDate, setCalendarDate] = useState(moment());
  const [transactions, setTransactions] = useState([])
  const [fulfilled, setFulfilled] = useState({})
  const [transaction, setTransaction] = useState([]);
  const [view, setView] = useState(false);
  const [loading, isLoading] = useState(false)
  const open = Boolean(selectEdge);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(40);
  const dataToExport = transactions.map(transaction => ({ ...transaction, channel: "Web" }));

  const [users, setUsers] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [details, setDetails] = useState({
    username: '',
    phone: '',
    role: '',
    id: ''
  })
  // const dataToExport = [...transactions, {

  // customer_name: '',
  // customer_phone: '',
  // customer_account_type: '',
  // payment_reference: '',
  // customer_business_unit: '',
  // amount: '',
  // debt_amount: '',
  // fixed_amount: '',
  // customer_address: '',
  // status: '',
  // channel : 'Web'
  // service: 'TOTAL',
  // payment_reference: fulfilled.total_amount,
  // is_branch: '',
  // union_commission: fulfilled.total_union_commission,
  // union_sc: fulfilled.total_union_sc,
  // union_total: fulfilled.total_union_total,
  // irecharge_amount: fulfilled.total_irecharge_amount,
  // irecharge_sc: fulfilled.total_irecharge_sc,
  // irecharge_total: fulfilled.total_irecharge_total,
  // }]
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCalendarOpen = edge => {
    setSelectEdge(edge);
  };

  const handleCalendarChange = date => {
    setCalendarDate(date);
  };

  const handleCalendarClose = () => {
    setCalendarDate(moment());
    setSelectEdge(null);
  };

  const handleCalendarAccept = date => {
    setCalendarDate(moment());

    if (selectEdge === 'start') {
      setStartDate(moment(date).format('Y-MM-DD'));

      if (moment(date).isAfter(endDate)) {
        setEndDate(moment(date).format('Y-MM-DD'));
      }
    } else {
      setEndDate(moment(date).format('Y-MM-DD'));

      if (moment(date).isBefore(startDate)) {
        setStartDate(moment(date).format('Y-MM-DD'));
      }
    }

    setSelectEdge(null);
  };

  const handleView = (transaction) => {
    setTransaction(transaction);
    setView(true);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const getUsers = async () => {
    const token = sessionStorage['token'];
    const passcode = CryptoJS.HmacSHA512(token, "12Ekedp34Sx").toString();
    const url = 'https://irecharge.com.ng/api/ekedp/get_users.php';
    isLoading(true);

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          passcode,
          username: '',
          page
          // action: 'get_fulfilled'
        })
      });
      const result = await response.json();
      if (result.status === '00') {
        setUsers(result.items)
        setTotalPages(result.total_pages)
        // return
        // setTransactions(result.data.items);
        // setFulfilled(result);
      }
      // isLoading(false)
    } catch (error) {
      console.log(error);
      Swal.fire('Error', 'Something went wrong!', 'error');
    } finally {
      isLoading(false)
    }
  }

  const format_str = (string) => {
    return string.replaceAll(" ", "_").toLowerCase()
  }

  React.useLayoutEffect(() => {
    isLoading(true)
    getUsers();
  }, [page]);
  // React.useEffect(() => {
  //   getUsers();
  // }, [page]);





  const handlePage = (next) => {
    if (next) {
      setPage(page + 1)
    } else {
      setPage(page - 1)
    }
  }

  const handleEdit = (username, phone, role, id) => {
    // setView(true)
    setDetails({
      username,
      phone,
      role,
      id
    })
  }

  const handleSubmit = async () => {
    const token = sessionStorage['token'];
    const passcode = CryptoJS.HmacSHA512(token, "12Ekedp34Sx").toString();
    let url = "https://irecharge.com.ng/api/ekedp/edit_user.php"

    isLoading(true);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          passcode,
          id: details.id,
          phone: details.phone,
          role: details.role
        })
      });
      const result = await response.json();
      if (result.status === '00') {
        Swal.fire({
          icon: 'success',
          text: 'User Edited successfully',
        })
        setView(false)
        getUsers()
      } else {
        setView(false)
        Swal.fire({
          icon: 'error',
          text: result.message,
          // position: 'top-end'
        })
      }
      // isLoading(false)
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Unable to connect',
        text: 'Please check your internet connection and try again'
      })
      // Swal.fire('Error', 'Something went wrong!', 'error');
    } finally {
      isLoading(false)
    }
  }

  useEffect(() => {
    details.phone && setView(true)
  }, [details])


  const handleSetStatus = async (id, status) => {
    const token = sessionStorage['token'];
    const passcode = CryptoJS.HmacSHA512(token, "12Ekedp34Sx").toString();
    let url
    if (status === 'Active') {
      url = 'https://irecharge.com.ng/api/ekedp/deactivate_user.php'
    } else {
      url = 'https://irecharge.com.ng/api/ekedp/activate_user.php'
    }
    isLoading(true);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          passcode,
          id
        })
      });
      const result = await response.json();
      if (result.status === '00') {
        Swal.fire({
          icon: 'success',
          text: 'User status updated successfully',
        })
        getUsers()
      }
      // isLoading(false)
    } catch (error) {
      console.log(error);
      Swal.fire('Error', 'Something went wrong!', 'error');
    }

  }

  if (loading) {
    return (
      // <h1 className="text-center mt-5">Loading, please wait</h1>
      <Loader title="" />
    )
  }

  return (
    <div className={classes.printContainer}>

      <Header />
      {/* Page content */}

      <Container className="mt--7">
        {/* Table */}
        {/* Dark table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0 pb-0">
                <h3 className="text-white mb-0">Users List</h3>
              </CardHeader>
              {!transactions ? <Typography variant="h5" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', color: '#fff', margin: '2rem' }}>No transactions found</Typography> :
                (<>
                  <Table
                    className="align-items-center table-dark table-flush"
                    responsive
                  >
                    <thead 
                    // className="thead-dark"
                    style={{backgroundColor:"#00897B"}}
                    >
                      <tr>
                        <th scope="col">Username</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Role</th>
                        <th scope="col">Status</th>
                        {/* <th scope="col">Account Number</th>
                        <th scope="col">Payment Reference </th> */}
                        {/* <th scope="col">Customer Business Unit </th> */}
                        {/* <th scope="col">Amount </th>
                        <th scope="col">Debt Amount </th> */}
                        {/* <th scope="col">Fixed Amount </th>
                    <th scope="col">Address  </th> */}
                        {/* <th scope="col">Status  </th> */}
                        <th scope="col">Action  </th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {
                        users && users.map((user, i) => {
                          return (
                            <tr key={user.username}>
                              <td>{user.username}</td>
                              <td>{user.phone}</td>
                              <td>{user.role}</td>
                              <td>
                                {/* {user.status} */}
                                {user.status === 'Active' ? (
                                  <span style={{ color: '#aef359' }}>{user.status}</span>
                                ) : (
                                  <span style={{ color: '#ff7377' }}>{user.status}</span>
                                )}
                              </td>
                              {/* <td>{transaction.customer_account_number}</td>
                              <td>{transaction.payment_reference}</td> */}
                              {/* <td>{transaction.customer_business_unit}</td> */}
                              {/* <td>{transaction.amount}</td>
                              <td>{transaction.debt_amount}</td> */}
                              {/* <td>{transaction.fixed_amount}</td>
                          <td>{transaction.customer_address}</td> */}
                              {/* <td>{transaction.status}</td> */}
                              {/* <td><Button variant="outlined" style={{ color: '#fff', borderColor: '#fff' }} onClick={() => handleView(transaction)}>View</Button></td> */}

                              {/* <UncontrolledDropdown>
                                <DropdownToggle caret variant="outlined" style={{ color: '#fff', borderColor: '#fff', backgroundColor: "#1F1456" }}>
                                  View
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem disabled>Change status</DropdownItem>
                                  <DropdownItem>Activate</DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem>Another Action</DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown> */}
                              <td>
                                <Button
                                  size="small"
                                  style={{ fontSize: 10, backgroundColor: '#0188c2', color: '#fff' }}
                                  onClick={() => handleSetStatus(user.id, user.status)}
                                >
                                  {user.status === 'Active' ? 'Deactivate' : 'Activate'}
                                </Button>
                                <Button
                                  size="small"
                                  style={{ fontSize: 10, backgroundColor: '#0188c2', color: '#fff', marginLeft: 4 }}
                                  onClick={() => handleEdit(user.username, user.phone, user.role, user.id)}
                                >
                                  Edit
                                </Button>
                              </td>

                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                  <div style={{ textAlign: 'right', marginTop: 16, paddingRight: 16 }}>
                    <Button
                      onClick={() => handlePage(false)}
                      disabled={page === 1}
                      style={{ color: '#fff' }}
                    >
                      Prev
                    </Button>
                    <Button
                      disabled={page === totalPages}
                      onClick={() => handlePage(true)}
                      style={{ color: '#fff' }}

                    >
                      Next
                    </Button>
                    <p style={{ color: '#fff', fontSize: 12 }}>
                      {`page ${page} of ${totalPages}`}
                    </p>
                  </div>
                  {/* <CardFooter className="py-4 bg-default shadow">
                    <nav aria-label="...">
                      <TablePagination
                        component="div"
                        count={transactions.length}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        style={{ color: '#fff' }}
                      />
                    </nav>
                  </CardFooter> */}
                  <Dialog onClose={() => setView(false)} aria-labelledby="simple-dialog-title" open={view} maxWidth="sm" fullWidth>
                    {/* <Dialog onClose={() => setView(false)} aria-labelledby="simple-dialog-title" open={view} fullWidth={true} maxWidth="md"> */}
                    <DialogTitle id="simple-dialog-title">Edit User Details</DialogTitle>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <Label for="phone1" style={{ paddingLeft: 8 }}>Phone</Label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            id={"phone1"}
                            onChange={handleChange}
                            value={details.phone}
                            name="phone" placeholder=" Phone" type="text"
                          // autocomplete="off" 
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <Label for="username1" style={{ paddingLeft: 8 }}>Username</Label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            id="username1"
                            disabled
                            onChange={handleChange}
                            value={details.username}
                            name="username" placeholder="User Name" type="email"
                          // autocomplete="off" 
                          />
                        </InputGroup>
                      </FormGroup>
                      <Col className="col-sm-12  col-md-12">
                        <FormGroup className="mb-3">
                          <Label for="exampleSelect">Role</Label>
                          <Input type="select" name="role" id="exampleSelect"
                            onChange={handleChange}
                          >
                            <option defaultValue>{details.role}</option>
                            {details.role !== 'admin' && <option value={"admin"}>admin</option>}
                            {details.role === 'admin' && <option value={"representative"}>representative</option>}

                          </Input>
                        </FormGroup>
                      </Col>
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id=" customCheckLogin"
                          type="checkbox"
                        />
                      </div>
                      <div>
                        <Spinner color="primary" />
                      </div>
                      <div className="text-center">
                        {/* <Button className="my-4" color="primary" type="button"
                          // onClick={this.handleChangePassword} 
                          disabled={isLoading}>
                          {isLoading ? 
                          <span ><i className="fas fa-spinner fa-spin"></i>Please wait...</span> 
                          : 'Submit'}
                        </Button> */}
                        <Button
                          onClick={handleSubmit}
                          style={{ fontSize: 16, backgroundColor: '#0188c2', color: '#fff', marginBottom: 16 }}

                        >
                          Submit
                        </Button>
                        <a className="text-right"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          {/* <small onClick={this.handlePassword}>Forgot password?</small> */}
                        </a>
                      </div>
                    </Form>
                  </Dialog>
                </>)}
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}


export default Fulfilled;
