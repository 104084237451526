import React, { Component } from "react";
import {
    Card,
    CardHeader,
    Container
} from "reactstrap";



class Loader extends Component {

    render() {

        return (

            <div>
                <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>

                <Container className="mt--9" fluid>
                    {/* Table */}
                    <div className="page-loading">
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <h3 className="mb-0">{this.props.title}</h3>
                                </CardHeader>
                                <div className="page-loader" style={{ textAlign: 'center' }}>
                                    <i className="fas fa-spinner fa-spin"></i>
                                    <div>Loading...</div>

                                </div>
                            </Card>
                        </div>
                    </div>
                </Container>
            </div>

        )
    }

};

export default Loader;
