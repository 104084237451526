import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Typography,
  colors,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import { orange } from '@material-ui/core/colors';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactExport from 'react-export-excel';
import {
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import Swal from 'sweetalert2';
import RegionsViewModal from './modals/RegionsViewModal';

import Header from 'components/Headers/DashboardHeader.jsx';
import { globalStyles } from '../..';

const OrangeRadio = withStyles({
  root: {
    color: orange[600],
    '&$checked': {
      color: orange[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function FileTypeButtons({ exportFileType, setExportFileType }) {
  const handleChange = (event) => {
    setExportFileType(event.target.value);
  };

  return (
    <div>
      {/* <label htmlFor="xlsx" className="text-white">
          .xlsx
        </label>
        <OrangeRadio
          checked={exportFileType === "xlsx"}
          onChange={handleChange}
          value="xlsx"
          name="radio-button-demo"
          id="xlxs"
          inputProps={{ "aria-label": "A" }}
        /> */}
      <label htmlFor="csv" className="text-white">
        csv
      </label>
      <OrangeRadio
        checked={exportFileType === 'csv'}
        onChange={handleChange}
        value="csv"
        id="csv"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'B' }}
      />
      <label htmlFor="pdf" className="text-white">
        pdf
      </label>
      <OrangeRadio
        checked={exportFileType === 'pdf'}
        onChange={handleChange}
        value="pdf"
        id="pdf"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'C' }}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  norec: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
  },
  search: {
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingLeft: 0,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '@media print': {
      display: 'none',
    },
  },
  searchMobile: {
    padding: theme.spacing(3),
    display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '@media print': {
      display: 'none',
    },
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
  },
  createBtn: {},
  form: {
    marginTop: theme.spacing(3),
    width: '100%',
    marginRight: theme.spacing(4),
  },
  formMobile: {
    marginTop: theme.spacing(3),
    minWidth: '80%',
    marginRight: theme.spacing(0),
  },
  text: {
    height: '100%',
  },
  createBtnMobile: {
    display: 'block',
    padding: '0 1.5rem',
    marginTop: theme.spacing(6),
  },
  right: {
    display: 'flex',
    width: '40%',
  },
  rightMobile: {
    display: 'flex',
    width: '80%',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    width: '90%',
    margin: '3rem auto 3rem',
  },
  contentMobile: {
    flexGrow: 1,
    padding: theme.spacing(0),
    width: '90%',
    margin: '3rem auto 0',
  },
  dates: {
    padding: 100,
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1),
  },
  printButton: {
    color: 'white',
    backgroundColor: colors.cyan[600],
    '&:hover': {
      backgroundColor: colors.cyan[900],
    },
    margin: theme.spacing(3),
    '@media print': {
      display: 'none',
    },
  },
  printIcon: {
    color: colors.white,
    backgroundColor: colors.cyan[600],
    '&:hover': {
      backgroundColor: colors.cyan[900],
    },
    marginRight: theme.spacing(1),
  },
  exportButton: {
    color: 'white',
    // backgroundColor: globalStyles.colors.secondary,
    // "&:hover": {
    //   backgroundColor: globalStyles.colors.secondaryDark,
    // },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '16px',
    },
    margin: theme.spacing(3),
    '@media print': {
      display: 'none',
    },
  },
  exportIcon: {
    color: 'white',
    backgroundColor: 'transaparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginRight: theme.spacing(1),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    padding: '1rem 1rem 0',
    height: '2rem',
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
    // [theme.breakpoints.down('sm')]: {
    //   '&:not(:last-of-type)': {
    //     borderBottom: `1px solid ${theme.palette.divider}`
    //   }
    // }
  },
  printContainer: {
    '@media print': {
      marginLeft: -250,
    },
  },
  getBtn: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    marginTop: '20px',
    marginLeft: '16px',
  },
  label: {
    color: 'white',
    // fontWeight:"bold"
  },
  labelBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  clearBtn: {
    color: 'white',
    fontSize: '.75rem',
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    outline: 'none',
  },
}));

const baseUrl = 'https://ibedc.irechargetech.com';

const INITIAL_DASHBOARD_DATA = {
  date: '',
  yesterdays: {
    count: 0,
    value: 0,
  },
  today: {
    count: 0,
    value: 0,
  },
  lastmonth: {
    count: 0,
    value: 0,
  },
  thisyear: {
    count: 0,
    value: 0,
  },
  thismonth: {
    count: 0,
    value: 0,
  },
};

export function formatCurrency(amount) {
  return amount.toLocaleString('en-NG', {
    style: 'currency',
    currency: 'NGN',
  });
}

const FulfilledAdminTransactions = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'days').format('Y-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment().format('Y-MM-DD'));
  const [selectEdge, setSelectEdge] = useState(null);
  const [calendarDate, setCalendarDate] = useState(moment());
  const [transactions, setTransactions] = useState([]);
  const [loading, isLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [meterNumber, setMeterNumber] = useState('');
  const open = Boolean(selectEdge);
  const [page, setPage] = React.useState(0);
  const [, setFilter] = useState({
    payment_channel: '',
    payment_type: '',
    start_date: '',
    end_date: '',
    account_number: '',
    meter_number: '',
    payment_reference: '',
    token: '',
    status: '',
  });
  const [exportData, setExportData] = useState(null);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [
    { lastmonth, thismonth, thisyear, yesterdays, today },
    setDashboardData,
  ] = useState(INITIAL_DASHBOARD_DATA);
  const [exportFileType, setExportFileType] = React.useState('csv');
  const [dashboardDataPrepaidPostpaid, setDashboardDataPrepaidPostpaid] =
    useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const handleUpdatePage = (isNext) => {
    if ((isNext && !hasNextPage) || (!isNext && page <= 0)) return;
    if (isNext) {
      setPage((prev) => prev + 1);
      return;
    }
    setPage((prev) => prev - 1);
  };

  const handleCalendarOpen = (edge) => {
    setSelectEdge(edge);
  };

  const handleCalendarChange = (date) => {
    setCalendarDate(date);
  };

  const handleCalendarClose = () => {
    setCalendarDate(moment());
    setSelectEdge(null);
  };

  const handleCalendarAccept = (date) => {
    setCalendarDate(moment());
    const result = moment(date).format('Y-MM-DD');

    if (selectEdge === 'start') {
      setStartDate(result);
      setFilter((prevState) => ({
        ...prevState,
        start_date: result,
      }));

      if (moment(date).isAfter(endDate)) {
        setEndDate(result);
        setFilter((prevState) => ({
          ...prevState,
          end_date: result,
        }));
      }
    } else {
      setEndDate(result);
      setFilter((prevState) => ({
        ...prevState,
        end_date: result,
      }));

      if (moment(date).isBefore(startDate)) {
        setStartDate(result);
        setFilter((prevState) => ({
          ...prevState,
          start_date: result,
        }));
      }
    }

    setSelectEdge(null);
  };

  const inputHandler = (event) => {
    const { name, value } = event.target;
    if (name === 'meter') {
      phone !== '' && setPhone('');
      setMeterNumber(value);
    } else if (name === 'phone') {
      meterNumber !== '' && setMeterNumber('');
      setPhone(value);
    }
  };

  const handleValidate = () => {
    if ((startDate && !endDate) || (!startDate && endDate)) {
      Swal.fire({
        icon: 'error',
        text: 'Please provide start and end dates',
        position: 'top-end',
      });
      return false;
    }

    if (!startDate && !endDate && !meterNumber && !phone) {
      Swal.fire({
        icon: 'error',
        text: 'Please provide a meter or phone number',
        position: 'top-end',
      });
      return false;
    }

    if (
      (meterNumber && meterNumber.length > 15) ||
      (phone && phone.length > 15)
    ) {
      Swal.fire({
        icon: 'error',
        text: 'Invalid input',
        position: 'top-end',
      });
      return false;
    }

    return true;
  };

  const getTransactions = async (e, isExport = false, isFreshQuery = false) => {
    if (!handleValidate()) return;

    let body = {
      range: {
        startDate,
        endDate,
      },
      search_cat: {
        phoneno: !!phone,
        meter_no: !!meterNumber,
      },
      value: meterNumber || phone,
      exp: !!isExport,
    };
    const token = sessionStorage['token'];
    let url = `${baseUrl}/report/${isFreshQuery ? 0 : page}`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };

    if (isExport) {
      setIsExportLoading(true);
    } else {
      isLoading(true);
    }

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      if (result && result.success === true) {
        if (isExport) {
          setExportData(result.data.items);
        } else {
          setTransactions(result.data.items);
          if (isFreshQuery) {
            setPage(0);
          }
          setHasNextPage(result.data.noPages);
        }
      } else {
        Swal.fire('Error', result.message, 'error');
      }
    } catch (error) {
      console.log(error);
      Swal.fire('Error', 'Something went wrong!', 'error');
    } finally {
      isLoading(false);
      setIsExportLoading(false);
    }
  };

  const exportTransactionsToEmail = async () => {
    if (!handleValidate()) return;

    let body = {
      range: {
        startDate: startDate.replaceAll('-', '/'),
        endDate: endDate.replaceAll('-', '/'),
      },
      search_cat: {
        phoneno: !!phone,
        meter_no: !!meterNumber,
      },
      value: meterNumber || phone,
      type: {
        pdf: exportFileType === 'pdf',
        csv: exportFileType === 'csv',
      },
    };
    const token = sessionStorage['token'];
    let url = `${baseUrl}/export`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };

    setIsExportLoading(true);

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      if (result && result.success === true) {
        Swal.fire('Success', 'File will be sent to your email', 'success');
      } else {
        Swal.fire('Error', result.message, 'error');
      }
    } catch (error) {
      console.log(error);
      Swal.fire('Error', 'Something went wrong!', 'error');
    } finally {
      setIsExportLoading(false);
    }
  };

  const handleClearDate = () => {
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    getTransactions();
  }, [page]);

  useEffect(() => {
    exportData &&
      exportData.length &&
      document.getElementById('export_btn').click();
  }, [exportData]);

  const getDashboardData = async () => {
    const token = sessionStorage['token'];
    const url = `${baseUrl}/reportcard`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && result.success === true) {
        setDashboardData(result.data);
      }
    } catch (error) {
      console.log(error);
      Swal.fire('Error', 'Something went wrong!', 'error');
    }
  };

  const getDashboardDataPrepaidPostpaid = async () => {
    const token = sessionStorage['token'];
    const url = `${baseUrl}/agg-service`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && result.success === true) {
        setDashboardDataPrepaidPostpaid(result.data);
      }
    } catch (error) {
      console.log(error);
      Swal.fire('Error', 'Something went wrong!', 'error');
    }
  };

  useEffect(() => {
    getDashboardData();
    getDashboardDataPrepaidPostpaid();
  }, []);

  return (
    <div className={classes.printContainer}>
      <Header
        data={[
          {
            title: "This Year's Transactions",
            value: `${
              (thisyear && formatCurrency(thisyear.value)) || formatCurrency(0)
            }`,
            count: (thisyear && thisyear.count) || 0,
            icon: 'ni ni-money-coins',
            iconStyle:
              'icon icon-shape bg-green text-white rounded-circle shadow',
            barStyle: 'bg-green',
            description: '',
          },
          {
            title: "Last Month's Transactions",
            value: `${
              (lastmonth && formatCurrency(lastmonth.value)) ||
              formatCurrency(0)
            }`,
            count: (lastmonth && lastmonth.count) || 0,
            icon: 'ni ni-money-coins',
            iconStyle:
              'icon icon-shape bg-info text-white rounded-circle shadow',
            barStyle: 'bg-info',
            description: '',
          },
          {
            title: "This Month's Transactions",
            value: `${
              (thismonth && formatCurrency(thismonth.value)) ||
              formatCurrency(0)
            }`,
            count: (thismonth && thismonth.count) || 0,
            icon: 'ni ni-money-coins',
            iconStyle:
              'icon icon-shape bg-green text-white rounded-circle shadow',
            barStyle: 'bg-green',
            description: '',
          },
          {
            title: "Yesterday's Transactions",
            value: `${
              (yesterdays && formatCurrency(yesterdays.value)) ||
              formatCurrency(0)
            }`,
            count: (yesterdays && yesterdays.count) || 0,
            icon: 'ni ni-money-coins',
            iconStyle:
              'icon icon-shape bg-info text-white rounded-circle shadow',
            barStyle: 'bg-info',
            description: '',
          },
          {
            title: "Today's Transactions",
            value: `${
              (today && formatCurrency(today.value)) || formatCurrency(0)
            }`,
            count: (today && today.count) || 0,
            icon: 'ni ni-money-coins',
            iconStyle:
              'icon icon-shape bg-yellow text-white rounded-circle shadow',
            barStyle: 'bg-yellow',
            description: '',
          },
          {
            title: "Today's Prepaid Transactions",
            value: `${
              (dashboardDataPrepaidPostpaid &&
                formatCurrency(dashboardDataPrepaidPostpaid.prepaid_amount)) ||
              formatCurrency(0)
            }`,
            count:
              (dashboardDataPrepaidPostpaid &&
                dashboardDataPrepaidPostpaid.prepaid_count) ||
              0,
            icon: 'ni ni-money-coins',
            iconStyle:
              'icon icon-shape bg-info text-white rounded-circle shadow',
            barStyle: 'bg-info',
            description: '',
          },
          {
            title: "Today's Postpaid Transactions",
            value: `${
              (dashboardDataPrepaidPostpaid &&
                formatCurrency(dashboardDataPrepaidPostpaid.postpaid_amount)) ||
              formatCurrency(0)
            }`,
            count:
              (dashboardDataPrepaidPostpaid &&
                dashboardDataPrepaidPostpaid.postpaid_count) ||
              0,
            icon: 'ni ni-money-coins',
            iconStyle:
              'icon icon-shape bg-yellow text-white rounded-circle shadow',
            barStyle: 'bg-yellow',
            description: '',
          },
        ]}
      />
      {/* Page content */}

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0 pb-0"></CardHeader>
              <div className={classes.search}>
                <div>
                  <Container fluid>
                    <Row className="">
                      <Col className="col-12  col-lg-3 pb-2" fluid>
                        <div className={classes.labelBox}>
                          <Label for="exampleSelect" className={classes.label}>
                            Select Date Range
                          </Label>
                          <button
                            className={classes.clearBtn}
                            onClick={handleClearDate}
                          >
                            Clear <ClearIcon className="text-xs text-danger" />
                          </button>
                        </div>

                        <ButtonGroup
                          variant="contained"
                          className={classes.createBtn}
                          style={{ width: '100%' }}
                        >
                          <Button
                            onClick={() => handleCalendarOpen('start')}
                            style={{ minWidth: '50%' }}
                          >
                            <CalendarTodayIcon
                              className={classes.calendarTodayIcon}
                            />
                            {startDate}
                          </Button>
                          <Button
                            onClick={() => handleCalendarOpen('end')}
                            style={{ minWidth: '50%' }}
                          >
                            <CalendarTodayIcon
                              className={classes.calendarTodayIcon}
                            />
                            {endDate}
                          </Button>
                        </ButtonGroup>
                      </Col>
                      <Col className="col-12  col-lg-3" fluid>
                        <FormGroup className="mb-3">
                          <FormGroup>
                            <Label for="meter" className={classes.label}>
                              Meter Number
                            </Label>
                            <Input
                              type="text"
                              id="meter"
                              name="meter"
                              value={meterNumber}
                              onChange={inputHandler}
                            />
                          </FormGroup>
                        </FormGroup>
                      </Col>
                      <Col className="col-12  col-lg-3" fluid>
                        <FormGroup className="mb-3">
                          <FormGroup>
                            <Label for="phone" className={classes.label}>
                              Phone Number
                            </Label>
                            <Input
                              type="text"
                              name="phone"
                              id="phone"
                              value={phone}
                              onChange={inputHandler}
                            />
                          </FormGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Container>
                  {!!loading && (
                    <div style={{ textAlign: 'center', padding: '1rem' }}>
                      <CircularProgress color="secondary" />
                    </div>
                  )}
                  <div>
                    <Row>
                      <Col className="col-12  col-md-4">
                        <Button
                          style={{
                            color: 'white',
                          }}
                          variant="contained"
                          color="secondary"
                          className={classes.getBtn}
                          onClick={(e) => getTransactions(e, false, true)}
                          disabled={loading}
                        >
                          Get Transactions
                        </Button>
                      </Col>

                      <Col className="col-12  col-md-4">
                        <Button
                          style={{
                            color: 'white',
                          }}
                          variant="contained"
                          color="secondary"
                          className={classes.getBtn}
                          onClick={() => setIsModalOpen(true)}
                        >
                          View Business Hubs
                        </Button>
                      </Col>
                      <Col
                        className="col-12  col-md-4"
                        style={{ textAlign: 'right' }}
                      >
                        <div>
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.exportButton}
                            onClick={(e) => {
                              exportFileType === 'xlsx'
                                ? getTransactions(e, true)
                                : exportTransactionsToEmail();
                            }}
                            disabled={isExportLoading}
                          >
                            <SaveAltIcon className={classes.exportIcon} />
                            {isExportLoading ? (
                              <CircularProgress color="secondary" size={20} />
                            ) : (
                              'Export'
                            )}
                          </Button>
                          <ExcelFile
                            filename={`Fulfilled Transactions for ${
                              startDate || ''
                            } - ${endDate || ''} ${
                              (!startDate && (meterNumber || phone)) || ''
                            }`}
                            element={
                              <Button
                                style={{ color: 'white', display: 'none' }}
                                variant="contained"
                                color="secondary"
                                className={classes.exportButton}
                                id="export_btn"
                              >
                                <SaveAltIcon className={classes.exportIcon} />
                                Export Data
                              </Button>
                            }
                          >
                            <ExcelSheet data={exportData} name="Transactions">
                              <ExcelColumn
                                label={'Transaction ID'}
                                value="transactionId"
                              />
                              <ExcelColumn
                                label={'RECEIVER'}
                                value="receiver"
                              />
                              <ExcelColumn
                                label={'CUSTOMER NAME'}
                                value="customerName"
                              />
                              <ExcelColumn label={'TOKEN'} value="token" />
                              <ExcelColumn label={'UNITS'} value="unit" />
                              <ExcelColumn label={'PHONE'} value="phone" />
                              <ExcelColumn
                                label={'DISPENSE AMOUNT'}
                                value="amountDispense"
                              />
                              <ExcelColumn
                                label={'CUSTOMER ACCOUNT SP'}
                                value="customerAccountSp"
                              />
                              <ExcelColumn
                                label={'TARIFF CODE'}
                                value="tariffCode"
                              />
                              <ExcelColumn
                                label={'CUSTOMER DISTRICT'}
                                value="customerDistrict"
                              />
                              <ExcelColumn
                                label={'UTILITY RECEIPT'}
                                value="utilReceipt"
                              />
                              <ExcelColumn label={'SERVICE'} value="service" />
                              <ExcelColumn
                                label={'PLATFORM'}
                                value="platform"
                              />
                              <ExcelColumn label={'TAX'} value="tax" />
                              <ExcelColumn
                                label={'RETAIL MESSAGE'}
                                value="retailMsg"
                              />
                              <ExcelColumn
                                label={'CUSTOMER MESSAGE'}
                                value="custMsg"
                              />
                              <ExcelColumn label={'AGENT ID'} value="agentId" />
                              <ExcelColumn label={'DATE'} value="timeStamp" />
                            </ExcelSheet>
                          </ExcelFile>
                        </div>
                        <FileTypeButtons
                          exportFileType={exportFileType}
                          setExportFileType={setExportFileType}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  maxDate={moment()}
                  onAccept={handleCalendarAccept}
                  onChange={handleCalendarChange}
                  onClose={handleCalendarClose}
                  open={open}
                  style={{ display: 'none' }} // Temporary fix to hide the input element
                  value={calendarDate}
                  variant="dialog"
                />
              </MuiPickersUtilsProvider>
              {!transactions ? (
                <Typography
                  variant="h5"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    color: '#fff',
                    margin: '2rem',
                  }}
                >
                  No transactions found
                </Typography>
              ) : (
                <>
                  <Table
                    className="align-items-center table-dark table-flush"
                    responsive
                  >
                    <thead
                      style={{ backgroundColor: globalStyles.colors.secondary }}
                    >
                      {/* <tr>
                        <th scope="col">Transaction ID </th>
                        <th scope="col">Meter Number</th>
                        <th>Customer Name</th>
                        <th>Token</th>
                        <th>Units</th>
                        <th scope="col">Phone</th>
                        <th>Amount Dispense</th>
                        <th>Customer Account Sp</th>
                        <th>Tariff Code</th>
                        <th>Customer District</th>
                        <th>Util Receipt</th>
                        <th>Service</th>
                        <th>Platform</th>
                        <th>Tax</th>
                        <th>Retail Message</th>
                        <th>Customer Message</th>
                        <th>Agent ID</th>
                        <th>Date</th>
                        <th scope="col" />
                      </tr> */}
                      <tr>
                        <th scope="col">Unique Trans Ref</th>
                        <th scope="col">Meter / Account No.</th>
                        <th>Customer Name</th>
                        <th>Token</th>
                        <th>Units</th>
                        <th scope="col">Phone No.</th>
                        <th>Amount</th>
                        <th>Business Hub</th>
                        <th>Band / Tariff Class</th>
                        <th>Service Center</th>
                        <th>Receipt No.</th>
                        <th>Transaction Type</th>
                        <th>Channel Used</th>
                        <th>VAT</th>
                        <th>DSS</th>
                        <th>Feeder</th>
                        <th>Cashier or Agent ID</th>
                        <th>Status</th>
                        <th>third party ID</th>
                        <th>Remark</th>
                        <th>Date & Time</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((transaction, i) => {
                        return (
                          <tr key={i}>
                            <td>{transaction.transactionId || 'N/A'}</td>
                            <td>{transaction.receiver || 'N/A'}</td>
                            <td>{transaction.customerName}</td>
                            <td>{transaction.token || 'N/A'}</td>
                            <td>{transaction.unit || 'N/A'}</td>
                            <td>{transaction.phone || 'N/A'}</td>
                            <td>{transaction.amountDispense || 'N/A'}</td>
                            <td>{transaction.customerAccountSp || 'N/A'}</td>

                            <td>{transaction.tariffCode || 'N/A'}</td>
                            <td>{transaction.customerDistrict || 'N/A'}</td>
                            <td>{transaction.utilReceipt || 'N/A'}</td>
                            <td>
                              {transaction.service === 'Ibadan_Disco_Prepaid'
                                ? 'Prepaid'
                                : transaction.service ===
                                  'Ibadan_Disco_Postpaid'
                                ? 'Postpaid'
                                : transaction.service || 'N/A'}
                            </td>
                            <td>{transaction.platform || 'N/A'}</td>

                            <td>{transaction.tax || 'N/A'}</td>
                            <td>{transaction.retailMsg || 'N/A'}</td>
                            <td>{transaction.custMsg || 'N/A'}</td>
                            <td>{transaction.agentId || 'N/A'}</td>
                            <td>{transaction.status || 'N/A'}</td>
                            <td>{transaction.transactionId || 'N/A'}</td>
                            <td>
                              {transaction.remark ? transaction.remark : 'N/A'}
                            </td>
                            <td>
                              {transaction.timeStamp
                                ? transaction.timeStamp
                                : 'N/A'}
                            </td>
                          </tr>
                          //   <tr key={i}>
                          //     <td>{transaction.transactionId || "N/A"}</td>
                          //     <td>{transaction.receiver || "N/A"}</td>
                          //     <td>{transaction.customerName}</td>
                          //     <td>{transaction.token || "N/A"}</td>
                          //     <td>{transaction.unit || "N/A"}</td>
                          //     <td>{transaction.phone || "N/A"}</td>
                          //     <td>{transaction.amountDispense || "N/A"}</td>
                          //     <td>{transaction.customerAccountSp || "N/A"}</td>

                          //     <td>{transaction.tariffCode || "N/A"}</td>
                          //     <td>{transaction.customerDistrict || "N/A"}</td>
                          //     <td>{transaction.utilReceipt || "N/A"}</td>
                          //     <td>
                          //       {transaction.service === "Ibadan_Disco_Prepaid"
                          //         ? "Prepaid"
                          //         : transaction.service ===
                          //           "Ibadan_Disco_Postpaid"
                          //         ? "Postpaid"
                          //         : transaction.service || "N/A"}
                          //     </td>
                          //     <td>{transaction.platform || "N/A"}</td>

                          //     <td>{transaction.tax || "N/A"}</td>
                          //     <td>{transaction.retailMsg || "N/A"}</td>
                          //     <td>{transaction.custMsg || "N/A"}</td>
                          //     <td>{transaction.agentId}</td>
                          //     <td>
                          //       {transaction.timeStamp
                          //         ? transaction.timeStamp
                          //         : "N/A"}
                          //     </td>
                          //   </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4 bg-default shadow">
                    {!loading &&
                      (!transactions || transactions.length === 0) && (
                        <div style={{ color: '#ffffff' }}>
                          No Transactions Found
                        </div>
                      )}
                    <nav
                      aria-label="..."
                      className="flex items-end"
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      {page > 0 && (
                        <Button
                          style={{ color: '#ffffff' }}
                          onClick={() => handleUpdatePage(false)}
                          disabled={loading}
                        >
                          <ArrowBackIosIcon />
                        </Button>
                      )}
                      {hasNextPage && (
                        <Button
                          style={{ color: '#ffffff' }}
                          onClick={() => handleUpdatePage(true)}
                          disabled={loading}
                        >
                          <ArrowForwardIosIcon />
                        </Button>
                      )}
                    </nav>
                    {!!loading && (
                      <div style={{ textAlign: 'center', padding: '1rem' }}>
                        <CircularProgress color="secondary" />
                      </div>
                    )}
                    <p
                      style={{
                        textAlign: 'right',
                        paddingRight: '1.125rem',
                        paddingTop: '1rem',
                        color: '#fcfcfc',
                      }}
                    >
                      Page {page + 1}
                    </p>
                  </CardFooter>
                </>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {isModalOpen && (
        <RegionsViewModal
          open={isModalOpen}
          setOpen={setIsModalOpen}
          baseUrl={baseUrl}
        />
      )}
    </div>
  );
};

export default FulfilledAdminTransactions;
