import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Spinner,
} from "reactstrap";
import { Button } from "@material-ui/core";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";

// const baseUrl = "http://34.198.147.232";

const baseUrl = "https://ibedc.irechargetech.com";

class Login extends React.Component {
  state = {
    username: "",
    password: "",
    isLoading: false,
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  // pause = (delay) =>
  //   new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve();
  //     }, delay);
  //   });

  // handleLogin = async () => {
  //   this.setState({
  //     isLoading: true,
  //   });
  //   const { username, password } = this.state;
  //   if (username !== "admin" || password !== "admin") {
  //     Swal.fire({
  //       icon: "error",
  //       text: "Please check your credentials",
  //       position: "top-end",
  //     });
  //     this.setState({
  //       isLoading: false,
  //     });
  //     return;
  //   }

  //   await this.pause(2000);
  //   sessionStorage.setItem("token", "jsonResponse.token");
  //   sessionStorage.setItem("name", "jsonResponse.company_name");
  //   sessionStorage.setItem("role", "admin");
  //   sessionStorage.setItem("isLoggedIn", true);
  //   this.props.history.push("/admin/get-account");
  // };

  // handleLogin2 = async (e) => {
  //   e.preventDefault();
  //   if (this.state.username == "" || this.state.password == "") {
  //     alert("All fileds are composary");
  //     this.setState({
  //       isLoading: false,
  //     });
  //   } else {
  //     this.setState({
  //       isLoading: true,
  //     });

  //     try {
  //       const { username, password } = this.state;
  //       const key = CryptoJS.HmacSHA512(username, "12Ekedp34Sx").toString();

  //       sessionStorage.setItem("passcode", key);

  //       const data = {
  //         username: username,
  //         action: "login",
  //         password: password,
  //         passcode: key,
  //       };

  //       const response = await fetch(
  //         "https://irecharge.com.ng/api/ekedp/login.php",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(data),
  //         }
  //       );
  //       const jsonResponse = await response.json();

  //       if (jsonResponse.status === "00") {
  //         // sessionStorage.setItem("login", JSON.stringify(jsonResponse));
  //         sessionStorage.setItem("token", jsonResponse.token);
  //         sessionStorage.setItem("name", jsonResponse.company_name);
  //         sessionStorage.setItem("role", jsonResponse.role);
  //         sessionStorage.setItem("isLoggedIn", true);
  //         Swal.fire({
  //           icon: "success",
  //           text: "Login successful",
  //           position: "top-end",
  //           showClass: {
  //             popup: "animated fadeInDown faster",
  //           },
  //           hideClass: {
  //             popup: "animated fadeOutUp faster",
  //           },
  //         });
  //         this.setState({
  //           isLoading: false,
  //         });
  //         this.props.history.push("/admin/transactions");
  //       } else {
  //         Swal.fire({
  //           icon: "error",
  //           text: jsonResponse.message,
  //           position: "top-end",
  //         });
  //         this.setState({
  //           isLoading: false,
  //         });
  //       }
  //     } catch (error) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Unable to connect",
  //         text: "Please check your internet connection and try again",
  //       });
  //     }
  //     this.setState({
  //       isLoading: false,
  //     });
  //   }
  // };

  maskString = (string) => {
    let atIndex;
    const stringInput = string.trim();
    for (let i = 0; i < stringInput.length; i++) {
      if (stringInput[i] === "@") {
        atIndex = i;
        break;
      }
    }
    if (!atIndex || stringInput.length < 5) {
      return stringInput;
    }
    const section1 = stringInput.slice(0, 5) + "***";
    const section2 = stringInput.slice(atIndex);
    return section1 + section2;
  };

  handleLogin = async (e) => {
    e.preventDefault();
    if (this.state.username == "" || this.state.password == "") {
      alert("All fileds are composary");
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: true,
      });

      try {
        const { username, password } = this.state;
        const key = CryptoJS.HmacSHA512(username, "12Ekedp34Sx").toString();

        sessionStorage.setItem("passcode", key);

        const data = {
          email: username.trim(),
          // action: "login",
          password,
          // passcode: key,
        };

        const response = await fetch(`${baseUrl}/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        const jsonResponse = await response.json();
        if (jsonResponse.success === true) {
          sessionStorage.setItem("token", jsonResponse.data);
          sessionStorage.setItem("role", "admin");
          sessionStorage.setItem("isLoggedIn", true);

          sessionStorage.setItem("user", this.maskString(username));
          // sessionStorage.setItem("name", jsonResponse.company_name);

          Swal.fire({
            icon: "success",
            text: "Login successful",
            position: "top-end",
            showClass: {
              popup: "animated fadeInDown faster",
            },
            hideClass: {
              popup: "animated fadeOutUp faster",
            },
          });
          // this.setState({
          //   isLoading: false,
          // });
          this.props.history.push("/admin/transactions", {
            token: jsonResponse.data,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: jsonResponse.massage,
            position: "top-end",
          });
          // this.setState({
          //   isLoading: false,
          // });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Unable to connect",
          text: "Please check your internet connection and try again",
        });
      } finally {
        this.setState({
          isLoading: false,
        });
      }
    }
  };

  handlePassword = (e) => {
    e.preventDefault();
    this.props.history.push("/auth/reset_password");
  };

  render() {
    const { isLoading } = this.state;
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center">
                <img
                  alt="..."
                  src={require("assets/img/brand/ibedc.jpeg")}
                  height="50px"
                  width="100px"
                />
              </div>
              <br></br>
              <div className="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02 text-grey" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.handleChange}
                      name="username"
                      placeholder="Username"
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.handleChange}
                      name="password"
                      placeholder="Password"
                      type="password"
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                </div>
                <div>
                  <Spinner color="primary" />
                </div>
                <div className="text-center">
                  <Button
                    type="button"
                    color="secondary"
                    variant="contained"
                    style={{ color: "white", margin: "16px 0" }}
                    onClick={this.handleLogin}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span>
                        <i className="fas fa-spinner fa-spin"></i>Please wait...
                      </span>
                    ) : (
                      "Sign In"
                    )}
                  </Button>
                  <a
                    className="text-right"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    {/* <small onClick={this.handlePassword}>Forgot password?</small> */}
                  </a>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Login;
